<template>
  <loading v-if="routing" :delay="100" />

  <aside v-else class="custom-menu">
    <template v-for="(route, ri) in routes">
      <p :key="`custom-menu-label-${ri}`" class="custom-menu-label">
        {{ route.label }}
      </p>

      <ul
        v-if="route.children"
        :key="`custom-menu-list-${ri}`"
        class="custom-menu-list"
      >
        <li
          v-for="(child, ci) in route.children"
          :key="`custom-menu-child-${ri}-${ci}`"
        >
          <router-link :to="child.to" @click.native="autoClose">{{
            child.label
          }}</router-link>

          <ul v-if="child.children">
            <li
              v-for="(subchild, sci) in child.children"
              :key="`custom-menu-subchild-${ri}-${ci}-${sci}`"
            >
              <a
                v-if="$_.isFunction(subchild.to)"
                @click="
                  subchild.to(vm);
                  autoClose($event);
                "
                >{{ subchild.label }}</a
              >

              <router-link v-else :to="subchild.to" @click.native="autoClose">{{
                subchild.label
              }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </template>
  </aside>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Menu",

  props: {
    routes: { required: true, type: Array }
  },

  computed: {
    ...mapState({
      windowWidth: state => state.ui.window.width,

      routing: state => state.ui.routing
    }),

    vm() {
      return this;
    }
  },

  methods: {
    autoClose($el) {
      const hasChildren =
        $el.target.nextElementSibling &&
        $el.target.nextElementSibling.localName == "ul"
          ? true
          : false;
      const isActive = $el.target.className
        .split(" ")
        .includes("router-link-active");
      if (!hasChildren || (hasChildren && isActive))
        return this.$store.commit("ui/adminMenu");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

.custom-menu {
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 500;
  padding: 1.5rem 1rem;
}

.custom-menu-label {
  color: rgba($dark-invert, 0.3);
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}

.custom-menu-list {
  line-height: 1.5;

  a {
    border-radius: 3px;
    color: rgba($dark-invert, 0.6);
    display: block;
    padding: 0.5em 0.75em;

    &:hover {
      background-color: $black;
      color: $black-invert;
    }

    &.router-link-active {
      background-color: $primary;
      color: $primary-invert;
    }

    &:not(.router-link-active) + ul {
      display: none;
    }
  }

  li ul {
    border-left: 2px solid rgba($dark-invert, 0.15);
    margin: 0.75em;
    padding-left: 0.75em;
  }
}
</style>
